import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import ty from "../styled-tachyons";

import Layout from "../components/Layout";
import {
  DarkGraySection,
  PageHeader,
  Card,
  BodyText,
  ns,
  rem,
  SectionHeader,
  FlexRow,
  Image,
} from "../styles";
import MetaTags from "../components/MetaTags";
import rocketImage from "../img/rocket-red.svg";
import vrHeadImage from "../img/vr-head-red.svg";
import resourcesImage from "../img/resources-red.svg";
import xrImage from "../img/xr-red.svg";

const ProgramsPageStyle = styled(DarkGraySection)`
  ${BodyText} {
    ${ty`center f6`}
    ${ns("max-width: 56.25rem")}
  }

  ${Card} {
    max-width: ${rem(350)};
    ${ty`washed-red ba b--gray mt3 `}

    &:not(:last-child) {
      ${ty`mr3-ns`}
    }
    ${SectionHeader} {
      ${ty`f3`}
    }
    ${Image}{
      min-height: ${rem(150)};
    }
  }

  ${FlexRow} {
    ${ty`flex-nowrap-ns mb5-ns justify-between-ns mw7 center justify-center`}
  }

  ${BodyText} + ${FlexRow} {
    ${ty`mt4`}
  }
`;

export const ProgramsPageTemplate = ({ title }) => (
  <>
    <MetaTags title={title} />
    <ProgramsPageStyle>
      <PageHeader
        css={`
          ${ty`tc`}
        `}
      >
        {title}
      </PageHeader>
      <BodyText noCenter>
        ARS provides technology and resources to artists whose interest aligns
        with these new mediums, allowing them to develop an alternative and
        complimentary means of expression to their existing practice. Artists
        are provided with equipment to realize a project that they define. When
        the work is completed, ARS collaborates with each artist and LA based
        arts institutions to exhibit the work.
      </BodyText>

      <BodyText noCenter>
        Our goal is to create a platform for new meaningful artist practices
        coupled with public engagement, dialogue, and education. ARS also hopes
        to foster a new frontier of collecting artworks that use cutting-edge
        technologies.
      </BodyText>
      <FlexRow>
        <Card>
          <SectionHeader>Virtual Reality</SectionHeader>
          <Image src={vrHeadImage} />
          <BodyText>
            ARS provides artists with state of the art VR equipment to
            experiment with and to determine if a work can be developed that
            furthers their practice. This includes the big world of XR -
            augmented reality, virtual reality therapy, and other screen based
            experiential space practices.
          </BodyText>
        </Card>
        <Card>
          <SectionHeader>New Tech</SectionHeader>
          <Image src={rocketImage} />
          <BodyText>
            ARS is interested in working with artists employing new technologies
            including neural networks and brain computer interface to explore
            the bounderies of these emerging tools.
          </BodyText>
        </Card>
      </FlexRow>
      <FlexRow>
        <Card>
          <SectionHeader>TAAC</SectionHeader>
          <Image src={resourcesImage} />
          <BodyText>
            ARS actively promotes the creation, collection, and exhibition of
            digital art practices by providing artists with a network of
            technical and administrative advisers TAAC.
          </BodyText>
        </Card>
        <Card>
          <SectionHeader>XR_BANK</SectionHeader>
          <Image src={xrImage} />
          <BodyText>
            ARS encourages institutions to create VR_banks and populate them
            with VR programs received as donations from collectors who purchase
            ARS Collector Edition 1 and purchases made through the institution's
            normal acquisition process. Potential users book time in the XR_bank
            online.
          </BodyText>
        </Card>
      </FlexRow>
    </ProgramsPageStyle>
  </>
);

ProgramsPageTemplate.propTypes = {};

const ProgramsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProgramsPageTemplate title={frontmatter.title} />
    </Layout>
  );
};

ProgramsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProgramsPage;

export const pageQuery = graphql`
  query ProgramsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "programs-page" } }) {
      frontmatter {
        title
      }
    }
  }
`;
